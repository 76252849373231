<template>
  <v-modal name="confirm-dialog" height="auto" width="430">
    <v-card>
      <v-card-title class="headline" primary-title>{{ confirmHandler.title }}</v-card-title>
      <v-card-text>
        <p>{{ confirmHandler.content || 'Biztosan törölni akarod?' }}</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text class="mr-2" @click="close">Mégse</v-btn>
        <v-btn text color="primary" @click="confirmHandler.handler">Megerősítés</v-btn>
      </v-card-actions>
    </v-card>
  </v-modal>
</template>
<script>
export default {
  name: 'ConfirmDialog',
  props: {
    confirmHandler: {
      type: Object,
      required: true,
      default: () => ({
        title: '',
        content: null,
        handler: () => {}
      })
    }
  },
  methods: {
    close() {
      this.$modal.hide('confirm-dialog')
    }
  }
}
</script>
